import * as React from 'react'
import Layout from '/src/components/layout'

const KakorPage = () => {
    return (
        <Layout pageTitle="Kakor">
            
            <h2>Du bestämmer!</h2>
            <p>På Laddify är det du som bestämmer om och i så fall typ av kakor som får lagras i den enhet. Med kakor avses även andra liknande tekniker.</p>

            <h2>Nödvändiga kakor</h2>
            <p>Dessa kakor är nödvändiga för att Laddify ska fungera.</p>
            <table>
                <tr>
                    <td>Typ:</td>
                    <td>Namn:</td>
                    <td>Syfte:</td>
                </tr>
                <tr>
                    <td>localStorage</td>
                    <td>cookiesConsent</td>
                    <td>Spara svar om medgivande, så att kakor-rutan inte behöver visas varje gång sidan laddas.</td>
                </tr>
                <tr>
                    <td>localStorage</td>
                    <td>filters</td>
                    <td>Spara användarens valda filteringar, så att hen inte behöver ange samma filter varje gång.</td>
                </tr>
                <tr>
                    <td>localStorage</td>
                    <td>favorites</td>
                    <td>Spara användarens valda favoriter, så att användarens favoriter finns kvar.</td>
                </tr>
                <tr>
                    <td><i>localStorage</i></td>
                    <td>_grecaptcha</td>
                    <td>XXX</td>
                </tr>
                <tr>
                    <td>XXX</td>
                    <td>XXX</td>
                    <td>XXX</td>
                </tr>
            </table>

            <h2>Alla kakor</h2>
            <p><i>Just nu inga fler än nödvändiga.</i></p>


            <h2>Beskrivning av typer</h2>
            <ul>
                <li><i>localStorage</i>: XXX</li>
                <li><i>cookie</i>: XXX</li>
            </ul>

        </Layout>
    )
}

export default KakorPage